<template>
    <div>
        <v-dialog
            v-model="showDialogPayments"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Payments
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Phase</label>
                            <v-combobox
                                v-model="phaseSelected"
                                :items="listPhase"
                                item-text="description"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                outlined
                                dense
                                @change="adjustValue"
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Type of Payment</label>
                            <v-combobox
                                v-model="typePaymentSelected"
                                :items="listTypePayments"
                                item-text="typePaymentsDescription"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row v-if="showCheckOrWireNumber">
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>{{ textCheckOrWireTransfer }} </label>
                            <v-text-field
                                v-model="checkNumber" 
                                :rules=[validations.required]
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-menu
                                ref="mnuPaymentDate"
                                v-model="mnuPaymentDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label>Payment Date</label>
                                    <v-text-field
                                        v-model="paymentDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        :rules=[validations.required]
                                        @blur="paymentDate = parseDate(paymentDateFormatted)"
                                        readonly
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="paymentDate"
                                    no-title
                                    @input="mnuPaymentDate = false"
                                    :max="today"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Value</label>
                            <v-text-field
                                v-model="value" 
                                v-formatMoney="moneyFormat"
                                :rules=[validations.required]
                                single-line
                                outlined
                                dense
                                :disabled="userLoggedGetters.profile.systemAdmin != 1"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Notes</label>
                            <v-textarea
                                v-model="notes"
                                single-line
                                rows="3"
                                row-height="20"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import { formaterDecimalBR, formaterDecimalBRServer } from '@/utilities/Utils';

	export default {

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },
			
            idServicePayment: {
                type: Number,
                default: 0
            },
			
            totalJob: {
                type: Number,
                default: 0
            },

			showDialogPayments: {
				default: false
			},
		},
		
        data: vm => ({

            listTypePayments: [],
			
            note: "",

            today: (new Date(Date.now() - ( new Date() ).getTimezoneOffset() * 60000) ).toISOString().substr(0, 10),
            paymentDate: vm.today,
            paymentDateFormatted: vm.formatDate(vm.today),
            mnuPaymentDate: false,
            
            phaseSelected: null,
            typePaymentSelected: null,
            checkNumber: '',
            value: "$ 0,00",
            notes: "",

            listPhase: [
                { id: 1, description: 'Deposit' },
                { id: 2, description: 'Excavation' },
                { id: 3, description: 'Gunite' },
                { id: 4, description: 'Tile Installation' },
                { id: 5, description: 'Day before Plaster' },
                { id: 6, description: 'Addendums' }
            ],

            moneyFormat: {
                prefix: '$ ',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },

            og: Math.pow(10, 2),
            
            validations: {
                required: required
            }
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            showCheckOrWireNumber() {
                return this.typePaymentSelected != null && 
                       this.typePaymentSelected != undefined && 
                      (this.typePaymentSelected.id == 1 || 
                       this.typePaymentSelected.id == 3)
            },

            textCheckOrWireTransfer() {
                if (this.isWireTransfer) {
                    return 'Tracking Number'
                }
                else if (this.isCheck) {
                    return 'Check Number'
                }
                else {
                    return ''
                }
            },

            isWireTransfer() {
                return this.showCheckOrWireNumber && this.typePaymentSelected.id == 1
            },

            isCheck() {
                return this.showCheckOrWireNumber && this.typePaymentSelected.id == 3
            }
        },

        watch: {

            async "id"() {
                this.clearFields();
				this.getRegisters();
            },

            paymentDate () {
                this.paymentDateFormatted = this.formatDate(this.paymentDate)
            },
        },

        methods: {

            clearFields() {
                this.phaseSelected = null;
                this.typePaymentSelected = null;
                this.checkNumber = '';
                this.value = "$ 0,00";
            },

            async getRegisters() {
                this.listTypePayments = await this.$store.dispatch("serviceModule/ListTypePayments");
            },
            
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            adjustValue() {

                let firstPayment = this.totalJob * 0.1;
                let secondAndThirdPayment = this.totalJob * 0.3;
                let fourthPayment = this.totalJob * 0.25;
                let lastPayment = this.totalJob * 0.05;

                firstPayment = `$ ${formaterDecimalBR(Math.floor(((firstPayment) * this.og)) / this.og)}`;
                secondAndThirdPayment = `$ ${formaterDecimalBR(Math.floor(((secondAndThirdPayment) * this.og)) / this.og)}`;
                fourthPayment = `$ ${formaterDecimalBR(Math.floor(((fourthPayment) * this.og)) / this.og)}`;
                lastPayment = `$ ${formaterDecimalBR(Math.floor(((lastPayment) * this.og)) / this.og)}`;

                if (this.phaseSelected != null && this.phaseSelected != undefined) {
                    switch (this.phaseSelected.id) {
                        case 1: this.value = firstPayment; break;
                        case 2: this.value = secondAndThirdPayment; break;
                        case 3: this.value = secondAndThirdPayment; break;
                        case 4: this.value = fourthPayment; break;
                        case 5: this.value = lastPayment; break;
                        case 6: this.value = "$ 0,00"; break;
                    
                        default:
                            break;
                    }
                }                
            },

            cancel() {
                this.$emit('update:showDialogPayments', false);
            },

            async confirm() {

                let id = this.id;
                let idServicePayment = this.idServicePayment;
                let payment1Type = null;
                let payment1CheckNumber = null;
                let payment1Date = null;
                let payment1Value = 0;
                let payment1Notes = '';

                let payment2Type = null;
                let payment2CheckNumber = null;
                let payment2Date = null;
                let payment2Value = 0;
                let payment2Notes = '';

                let payment3Type = null;
                let payment3CheckNumber = null;
                let payment3Date = null;
                let payment3Value = 0;
                let payment3Notes = '';

                let payment4Type = null;
                let payment4CheckNumber = null;
                let payment4Date = null;
                let payment4Value = 0;
                let payment4Notes = '';

                let payment5Type = null;
                let payment5CheckNumber = null;
                let payment5Date = null;
                let payment5Value = 0;
                let payment5Notes = '';

                let paymentAddendumType = null;
                let paymentAddendumCheckNumber = null;
                let paymentAddendumDate = null;
                let paymentAddendumValue = 0;
                let paymentAddendumNotes = '';

                if (this.phaseSelected != null && this.phaseSelected != undefined) {

                    let paymentType = null;
                    let paymentDate = this.paymentDate;
                    let value = formaterDecimalBRServer(this.value);
                    let notes = this.notes;

                    if (this.typePaymentSelected != null && this.typePaymentSelected != undefined) {
                        paymentType = this.typePaymentSelected.id;
                    }
                    
                    switch (this.phaseSelected.id) {
                        case 1: //Deposit
                            payment1Type = paymentType;
                            payment1CheckNumber = this.checkNumber;
                            payment1Date = paymentDate;
                            payment1Value = value;
                            payment1Notes = notes;
                            break;

                        case 2: //Excavation
                            payment2Type = paymentType;
                            payment2CheckNumber = this.checkNumber;
                            payment2Date = paymentDate;
                            payment2Value = value;
                            payment2Notes = notes;
                            break;

                        case 3: //Gunite
                            payment3Type = paymentType;
                            payment3CheckNumber = this.checkNumber;
                            payment3Date = paymentDate;
                            payment3Value = value;
                            payment3Notes = notes;
                            break;

                        case 4: //Tile Installation
                            payment4Type = paymentType;
                            payment4CheckNumber = this.checkNumber;
                            payment4Date = paymentDate;
                            payment4Value = value;
                            payment4Notes = notes;
                            break;

                        case 5: //Day before Plaster
                            payment5Type = paymentType;
                            payment5CheckNumber = this.checkNumber;
                            payment5Date = paymentDate;
                            payment5Value = value;
                            payment5Notes = notes;
                            break;

                        case 6: //Addendum
                            paymentAddendumType = paymentType;
                            paymentAddendumCheckNumber = this.checkNumber;
                            paymentAddendumDate = paymentDate;
                            paymentAddendumValue = value;
                            paymentAddendumNotes = notes;
                            break;
                    
                        default:
                            break;
                    }
                }

                let servicePaymentRequest = {
                    id: idServicePayment,
                    idService: id,
                    payment1Type,
                    payment1CheckNumber,
                    payment1Date,
                    payment1Value,
                    payment1Notes,

                    payment2Type,
                    payment2CheckNumber,
                    payment2Date,
                    payment2Value,
                    payment2Notes,

                    payment3Type,
                    payment3CheckNumber,
                    payment3Date,
                    payment3Value,
                    payment3Notes,

                    payment4Type,
                    payment4CheckNumber,
                    payment4Date,
                    payment4Value,
                    payment4Notes,

                    payment5Type,
                    payment5CheckNumber,
                    payment5Date,
                    payment5Value,
                    payment5Notes,

                    paymentAddendumType,
                    paymentAddendumCheckNumber,
                    paymentAddendumDate,
                    paymentAddendumValue,
                    paymentAddendumNotes
                };
                
                let result = await this.$store.dispatch("serviceModule/CreateUpdatePayments", servicePaymentRequest);

                if (result.success) {
                    this.showToast("success", "Success!", "Payment registered successfully!");
                
                    this.$emit('methodConfirmToCall');
                    this.cancel();
                }
            }
        },

        async created() {
            this.getRegisters()
        }
    };
</script>