<template>
    <div>
        <v-dialog
            v-model="showDialogNotes"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Communication for the customer
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-menu
                                ref="mnuExpirationDate"
                                v-model="mnuExpirationDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label>Expiration Date</label>
                                    <v-text-field
                                        v-model="expirationDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        :rules=[validations.required]
                                        @blur="expirationDate = parseDate(expirationDateFormatted)"
                                        readonly
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="expirationDate"
                                    no-title
                                    @input="mnuExpirationDate = false"
                                    :min="today"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Classification</label>
                            <v-combobox
                                v-model="classificationNotesSelected"
                                :items="listClassificationNotes"
                                item-text="classificationDescription"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>                        
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Message</label>
                            <v-textarea
                                v-model="note"
                                rows="4"
                                row-height="23"
                                auto-grow
                                outlined
                            >
                            </v-textarea>

                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";

	export default {

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },

			showDialogNotes: {
				default: false
			},
		},
		
        data: vm => ({
			
            note: "",

            today: (new Date(Date.now() - ( new Date() ).getTimezoneOffset() * 60000) ).toISOString().substr(0, 10),
            expirationDate: vm.today,
            expirationDateFormatted: vm.formatDate(vm.today),
            mnuExpirationDate: false,
            
            classificationNotesSelected: null,
            listClassificationNotes: [],
            
            validations: {
                required: required
            }
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        watch: {

            expirationDate () {
                this.expirationDateFormatted = this.formatDate(this.expirationDate)
            },
        },

        methods: {
            
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            cancel() {
                this.$emit('update:showDialogNotes', false);
            },

            async getLists() {
                this.listClassificationNotes = await this.$store.dispatch("serviceModule/ListClassification");
            },

            async confirm() {

                let noteRequest = null;
                let idService = this.id;
                let idServiceProject = 0;
                let idProfile = this.userLoggedGetters.profile.id;
                let idPanel = 0;
                let idStage = 0;
                let showToCustomer = 1;
                let expirationDate = this.expirationDate;
                let classification = null;

                if (this.classificationNotesSelected != null && this.classificationNotesSelected != undefined) {
                    classification = this.classificationNotesSelected.id;
                }

                noteRequest = {
                    id: 0,
                    idService,
                    idServiceProject,
                    idProfile,
                    idPanel,
                    idStage,
                    description: this.note.trim(),
                    showToCustomer,
                    expirationDate,
                    classification
                }

                const result = await this.$store.dispatch("serviceModule/AddServiceNote", noteRequest);

                if (result.success === true) {
                    this.showToast("success", "Success!", "Communication sent to the customer successfully!");
                    this.$emit('methodConfirmToCall');
                    this.cancel();
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }
            }
        },

        created() {
            this.getLists();
        }
    };
</script>