<template>
    <div>
        <v-dialog
            v-model="showDialogPaymentsDetails"
            transition="dialog-top-transition"
            persistent
            width="1100"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Payments
                </v-card-title>

                <v-card-text style="margin-top: 20px;">

                    <v-row>
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Payment</label>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <label>Type</label>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <label>Number</label>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <label>Value</label>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <label>Notes</label>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                            style="margin-bottom: 20px;"
                        >
                            <v-divider />
                        </v-col>
                    </v-row>

                    <v-row 
                        v-if="noPayments"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                            style="margin-bottom: 20px; text-align: center;"
                        >
                            <span> No payments registered </span>
                        </v-col>
                    </v-row>

                    <!-- PAYMENT 1 -->
                    <v-row 
                        v-if="hasPayment1"
                    >
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                            class="marginBottom"
                        >
                            <label>Deposit</label> - <span> {{ paymentResponse.payment1Register }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment1TypeDescription }} </span>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment1CheckNumber }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment1ValueFormatted }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment1Notes }} </span>
                        </v-col>
                    </v-row>

                    <!-- PAYMENT 2 -->
                    <v-row 
                        v-if="hasPayment2"
                    >
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                            class="marginBottom"
                        >
                            <label>Excavation</label> - <span> {{ paymentResponse.payment2Register }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment2TypeDescription }} </span>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment2CheckNumber }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment2ValueFormatted }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment2Notes }} </span>
                        </v-col>
                    </v-row>

                    <!-- PAYMENT 3 -->
                    <v-row 
                        v-if="hasPayment3"
                    >
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                            class="marginBottom"
                        >
                            <label>Gunite</label> - <span> {{ paymentResponse.payment3Register }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment3TypeDescription }} </span>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment3CheckNumber }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment3ValueFormatted }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment3Notes }} </span>
                        </v-col>
                    </v-row>

                    <!-- PAYMENT 4 -->
                    <v-row 
                        v-if="hasPayment4"
                    >
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                            class="marginBottom"
                        >
                            <label>Tile Installation</label> - <span> {{ paymentResponse.payment4Register }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment4TypeDescription }} </span>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment4CheckNumber }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment4ValueFormatted }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment4Notes }} </span>
                        </v-col>
                    </v-row>

                    <!-- PAYMENT 5 -->
                    <v-row 
                        v-if="hasPayment5"
                    >
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                            class="marginBottom"
                        >
                            <label>Day before Plaster</label> - <span> {{ paymentResponse.payment5Register }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment5TypeDescription }} </span>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment5CheckNumber }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment5ValueFormatted }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.payment5Notes }} </span>
                        </v-col>
                    </v-row>

                    <!-- PAYMENT ADDENDUM -->
                    <v-row 
                        v-if="hasPaymentAddendum"
                    >
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                            class="marginBottom"
                        >
                            <label>Addendums</label> - <span> {{ paymentResponse.paymentAddendumRegister }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.paymentAddendumTypeDescription }} </span>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.paymentAddendumCheckNumber }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.paymentAddendumValueFormatted }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentResponse.paymentAddendumNotes }} </span>
                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonDismiss"
							outlined
							@click="dismiss()"
						>
							Dismiss
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';

	export default {

        mixins: [Helpers],

        props: {
			
            idAux: {
                type: Number,
                default: 0
            },
			
            idServicePayment: {
                type: Number,
                default: 0
            },

			showDialogPaymentsDetails: {
				default: false
			},
		},
		
        data: () => ({
            paymentResponse: {
                payment1NameUserRegister: '',
                payment1TypeDescription: '',
                payment1CheckNumber: '',
                payment1DateFormatted: '',
                payment1ValueFormatted: '',
                payment1Notes: '',
                payment1Register: '',

                payment2NameUserRegister: '',
                payment2TypeDescription: '',
                payment2CheckNumber: '',
                payment2DateFormatted: '',
                payment2ValueFormatted: '',
                payment2Notes: '',
                payment2Register: '',

                payment3NameUserRegister: '',
                payment3TypeDescription: '',
                payment3CheckNumber: '',
                payment3DateFormatted: '',
                payment3ValueFormatted: '',
                payment3Notes: '',
                payment3Register: '',

                payment4NameUserRegister: '',
                payment4TypeDescription: '',
                payment4CheckNumber: '',
                payment4DateFormatted: '',
                payment4ValueFormatted: '',
                payment4Notes: '',
                payment4Register: '',

                payment5NameUserRegister: '',
                payment5TypeDescription: '',
                payment5CheckNumber: '',
                payment5DateFormatted: '',
                payment5ValueFormatted: '',
                payment5Notes: '',
                payment5Register: '',

                paymentAddendumNameUserRegister: '',
                paymentAddendumTypeDescription: '',
                paymentAddendumCheckNumber: '',
                paymentAddendumDateFormatted: '',
                paymentAddendumValueFormatted: '',
                paymentAddendumNotes: '',
                paymentAddendumRegister: ''
            }
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            noPayments() {
                return this.paymentResponse == null || this.paymentResponse == undefined || this.paymentResponse.id == 0;
            },

            hasPayment1() {
                return this.noPayments == false && this.paymentResponse.payment1Value != 0;
            },

            hasPayment2() {
                return this.noPayments == false && this.paymentResponse.payment2Value != 0;
            },

            hasPayment3() {
                return this.noPayments == false && this.paymentResponse.payment3Value != 0;
            },

            hasPayment4() {
                return this.noPayments == false && this.paymentResponse.payment4Value != 0;
            },

            hasPayment5() {
                return this.noPayments == false && this.paymentResponse.payment5Value != 0;
            },

            hasPaymentAddendum() {
                return this.noPayments == false && this.paymentResponse.paymentAddendumValue != 0;
            }
        },

        watch: {
            async "idAux"() {
				this.getRegister();
            },
        },

        methods: {

            async getRegister() {
                let response = await this.$store.dispatch("serviceModule/GetPaymentsById", this.idServicePayment);

                this.paymentResponse = response.result;
            },

            dismiss() {
                this.$emit('update:showDialogPaymentsDetails', false);
            },
        },
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .marginBottom {
        margin-bottom: 0px;
    }

</style>